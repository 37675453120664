






// Models
import {
  TimelineChartData,
  TimelineChartSeries
} from "@/models/reporting-analytics/timeline-data";

// Services
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";

// Shared
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";

// Store
import RecognitionRateAnalyticsStore from "@/store/reporting-analytics/recognition-rate.store";

// Third Party Libs
import { getModule } from "vuex-module-decorators";
import { Vue, Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { utils } from "@/utils/okta-utils";

@Component({
  components: { apexchart: VueApexCharts }
})
export default class RecogTimelineChart extends Vue {
  // store module
  private readonly recognitionStore: RecognitionRateAnalyticsStore = getModule(
    RecognitionRateAnalyticsStore,
    this.$store
  );

  // computed properties
  get recogHistoricalData() {
    return this.recognitionStore.getRecogHistoricalData;
  }
  get dateRange() {
    return this.recognitionStore.getDateRange;
  }
  get searchButtonClicked() {
    return this.recognitionStore.getSearchButtonClicked;
  }

  // reactive class properties
  private series: TimelineChartSeries[] = [
    {
      name: "Daily recognition rate",
      data: [],
      type: "line",
      color: "#D06C49"
    },
    {
      name: "Fields used to calculate rate",
      data: [],
      type: "bar",
      color: "#d3d3d3"
    }
  ];
  private chartOptions = {
    chart: {
      fontFamily: "'Lato', sans-serif",
      toolbar: {
        export: {
          csv: {
            filename: `daily-average-recognition-${this.dateRange.startDate}-to-${this.dateRange.endDate}`,
            columnDelimiter: ",",
            headerCategory: "Entered Date"
          }
        }
      }
    },
    grid: {
      padding: {
        left: 25,
        right: 25
      }
    },
    xaxis: {
      type: "category",
      labels: {
        formatter: (value: string) => {
          if (value) {
            return utils.utcToLocalDateString(moment(value).toDate(), "L");
          }
        }
      },
      title: {
        text: "Entered date"
      }
    },
    yaxis: [
      {
        title: {
          text: "Recognition rate (%)"
        },
        labels: {
          formatter: function(value: number) {
            if (value) {
              return Math.floor(value);
            }
          }
        },
        min: 0,
        max: 100
      },
      {
        opposite: true,
        title: {
          text: "Number of fields analyzed"
        },
        labels: {
          formatter: function(value: number) {
            if (value) {
              return parseFloat(value.toFixed()).toLocaleString();
            }
          }
        }
      }
    ],
    dataLabels: {
      enabled: false
    },
    states: {
      normal: {
        filter: {
          type: "desaturate"
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "darken",
          value: 0.25
        }
      },
      hover: {
        filter: {
          type: "darken",
          value: 0.25
        }
      }
    },
    stroke: {
      curve: "smooth",
      width: [2, 0]
    },
    title: {
      text: "Daily Average Recognition Rate",
      align: "left"
    },
    subtitle: {
      text: "Daily average across all fields",
      align: "left"
    },
    tooltip: {
      x: {
        format: "MMMM d yyyy"
      },
      y: [
        {
          formatter: function(value: any) {
            if (value) {
              return value.toString().concat("%");
            }
          }
        },
        {
          formatter: function(value: number) {
            if (value) {
              return parseFloat(value.toFixed()).toLocaleString();
            }
          }
        }
      ]
    },
    noData: {
      text: "No data for selected options",
      align: "center"
    }
  };

  // lifecycle methods
  async created() {
    if (Object.keys(this.recogHistoricalData).length === 0) {
      await this.fetchNewChartData();
    }
    this.updateChart();
    this.$emit("chartLoaded");
  }

  // methods
  @Catch((error: any, context: any) => {
    context.$emit("isLoadingRecognitionTime", false);
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async fetchNewChartData() {
    this.$emit("isLoadingRecognitionTime", true);
    const chartData: {
      [key: string]: TimelineChartData[];
    } = await reportingAnalyticsService.loadRecogHistoricalAsync(
      this.dateRange.startDate,
      this.dateRange.endDate,
      true // include VS
    );

    this.recognitionStore.updateRecogHistoricalData(chartData);
    this.$emit("isLoadingRecognitionTime", false);
  }

  updateChart() {
    this.series = [
      {
        name: "Daily recognition rate",
        data: this.recogHistoricalData.recogRate,
        type: "line",
        color: "#D06C49"
      },
      {
        name: "Fields used to calculate rate",
        data: this.recogHistoricalData.sampleSize,
        type: "bar",
        color: "#d3d3d3"
      }
    ];
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewChartData();
    this.updateChart();
    this.$emit("chartLoaded");
  }
}
