





































































// Custom Components
import DateRangeSelector from "@/components/forms/reporting-analytics/DateRangeSelector.vue";
import DataSnapshot from "@/components/reporting-analytics/data/DataSnapshot.vue";
import RecogByHeaderFieldChart from "@/components/reporting-analytics/charts/RecognitionByField.vue";
import RecogBySupplierChart from "@/components/reporting-analytics/charts/RecognitionBySupplier.vue";
import RecogTimelineChart from "@/components/reporting-analytics/charts/RecognitionTimeline.vue";
import RecognitionRateSkeleton from "@/components/skeletons/RecognitionRateSkeleton.vue";

// Store
import RecognitionRateAnalyticsStore from "@/store/reporting-analytics/recognition-rate.store";

// Third Party Libs
import { getModule } from "vuex-module-decorators";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    "date-range-selector": DateRangeSelector,
    "data-snapshot": DataSnapshot,
    "recog-timeline-chart": RecogTimelineChart,
    "recog-header-field-chart": RecogByHeaderFieldChart,
    "recog-supplier-chart": RecogBySupplierChart,
    skeleton: RecognitionRateSkeleton
  }
})
export default class RecognitionRateAnalytics extends Vue {
  // reactive class properties
  private timelineChartLoading = false;
  private headerFieldChartLoading = false;
  private supplierChartLoading = false;

  // store module
  private recognitionRateStore: RecognitionRateAnalyticsStore = getModule(
    RecognitionRateAnalyticsStore,
    this.$store
  );

  // computed properties
  private get dateRange() {
    return this.recognitionRateStore.getDateRange;
  }
  private get averageOverallRecogRate() {
    return this.recognitionRateStore.getAverageOverallRecogRate;
  }
  private get fieldsAnalyzed() {
    return this.recognitionRateStore.getFieldsAnalyzed;
  }
  private get numberOfSuppliers() {
    return this.recognitionRateStore.getNumberOfSuppliers;
  }
  private get searchButtonClicked() {
    return this.recognitionRateStore.getSearchButtonClicked;
  }

  async handleDateRangeChange(selection: {
    startDate: string;
    endDate: string;
  }) {
    this.recognitionRateStore.updateDateRange(selection);
    this.recognitionRateStore.clickRecogSearchButton();
  }

  private isLoadingTimelineChart(value) {
    this.timelineChartLoading = value;
  }
  private isLoadingHeaderFieldChart(value) {
    this.headerFieldChartLoading = value;
  }
  private isLoadingSupplierChart(value) {
    this.supplierChartLoading = value;
  }
}
