






























// Custom Components

// Models
import {
  CategoryChartData,
  CategoryChartSeries
} from "@/models/reporting-analytics/category-chart-data";

// Services
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";

// Shared
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";

// Store
import RecognitionRateAnalyticsStore from "@/store/reporting-analytics/recognition-rate.store";

// Third Party Libs
import { getModule } from "vuex-module-decorators";
import { Vue, Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";

@Component({
  components: { apexchart: VueApexCharts }
})
export default class RecogTimelineChart extends Vue {
  // store module
  private readonly recognitionStore: RecognitionRateAnalyticsStore = getModule(
    RecognitionRateAnalyticsStore,
    this.$store
  );

  // computed properties
  get recogBySupplierData() {
    return this.recognitionStore.getRecogBySupplierData;
  }
  get supplierInvoiceVolumeData() {
    return this.recognitionStore.getSupplierInvoiceVolumeData;
  }
  get dateRange() {
    return this.recognitionStore.getDateRange;
  }
  get searchButtonClicked() {
    return this.recognitionStore.getSearchButtonClicked;
  }

  // reactive class properties
  private loadingHeaderFieldRecogData = false;
  private series: CategoryChartSeries[] = [];
  private headerRecogSeries: CategoryChartSeries[] = [];
  private chartOptions = {
    chart: {
      id: "recogBySupplier",
      fontFamily: "'Lato', sans-serif",
      toolbar: {
        export: {
          csv: {
            filename: `recognition-by-supplier-${this.dateRange.startDate}-to-${this.dateRange.endDate}`,
            columnDelimiter: ",",
            headerCategory: "Supplier"
          }
        }
      },
      events: {
        dataPointSelection: async (e: any, chart: any, opts: any) => {
          const recogBySupplierChart = document.querySelector(
            "#recog-by-supplier"
          );
          const headerRecogChartForSupplier = document.querySelector(
            "#header-recog-for-supplier"
          );
          if (opts.selectedDataPoints[0]) {
            if (
              opts.selectedDataPoints[0].length === 1 &&
              headerRecogChartForSupplier != null &&
              headerRecogChartForSupplier.classList.contains("active")
            ) {
              this.updateHeaderRecogChart(chart, "headerRecogChartForSupplier");
            } else if (
              opts.selectedDataPoints[0].length === 1 &&
              headerRecogChartForSupplier != null &&
              recogBySupplierChart != null
            ) {
              recogBySupplierChart.classList.add(
                "header-recog-chart-activated"
              );
              headerRecogChartForSupplier.classList.add("active");
              this.updateHeaderRecogChart(chart, "headerRecogChartForSupplier");
            }
          }
          if (
            recogBySupplierChart != null &&
            headerRecogChartForSupplier != null &&
            opts.selectedDataPoints[0]
          ) {
            if (opts.selectedDataPoints[0].length === 0) {
              recogBySupplierChart.classList.remove(
                "header-recog-chart-activated"
              );
              headerRecogChartForSupplier.classList.remove("active");
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    states: {
      normal: {
        filter: {
          type: "desaturate"
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "darken",
          value: 0.25
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    yaxis: [
      {
        title: {
          text: "Recognition rate (%)"
        },
        min: 0,
        max: 100,
        labels: {
          formatter: function(value: number) {
            if (value) {
              return Math.floor(value);
            }
          }
        }
      },
      {
        opposite: true,
        title: {
          text: "Percent of total documents submitted"
        },
        min: 0,
        max: 100,
        labels: {
          formatter: function(value: number) {
            if (value) {
              return Math.floor(value);
            }
          }
        }
      }
    ],
    xaxis: {
      type: "category",
      title: {
        text: "Supplier"
      },
      labels: {
        formatter: (value: any) => {
          if (value.length) {
            const supplierIdIndex = value.lastIndexOf("/");
            return value.substring(supplierIdIndex + 1);
          }
        }
      }
    },
    title: {
      text: "Recognition Rate by Supplier",
      align: "left"
    },
    subtitle: {
      text: "Top 20 suppliers by document volume",
      align: "left"
    },
    tooltip: {
      // shared: true,
      followCursor: true,
      x: {
        formatter: function(value: any) {
          if (value.length) {
            const supplierIdIndex = value.lastIndexOf("/");
            return value
              .substring(0, supplierIdIndex - 1)
              .concat(` (${value.substring(supplierIdIndex + 1)})`);
          }
        }
      },
      y: {
        formatter: function(value: any) {
          if (value) {
            return value.toString().concat("%");
          }
        }
      }
    },
    noData: {
      text: "No data for selected options",
      align: "center"
    }
  };
  private headerRecogChartOptions = {
    chart: {
      id: "headerRecogForSelectedSupplier",
      type: "bar",
      fontFamily: "'Lato', sans-serif",
      toolbar: {
        export: {
          csv: {
            filename: `selected-supplier-header-field-recognition-${this.dateRange.startDate}-to-${this.dateRange.endDate}`,
            columnDelimiter: ",",
            headerCategory: "Field"
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: "Recognition Rate by Field",
      align: "left"
    },
    subtitle: {
      text: "Selected supplier",
      align: "left"
    },
    yaxis: [
      {
        title: {
          text: "Recognition rate (%)"
        },
        min: 0,
        max: 100
      },
      {
        opposite: true,
        title: {
          text: "Fields used to calculate rate"
        }
      }
    ],
    xaxis: {
      type: "category",
      title: {
        text: "Header field"
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      shared: true,
      intersect: false,
      marker: {
        show: true
      },
      y: [
        {
          formatter: function(value: any) {
            if (value) {
              return value.toString().concat("%");
            } else {
              return "0%";
            }
          }
        },
        {
          formatter: function(value: any) {
            if (value) {
              return value;
            }
          }
        }
      ]
    },
    noData: {
      text: "No data for selected options",
      align: "center"
    }
  };

  // lifecycle methods
  async created() {
    if (Object.keys(this.recogBySupplierData).length === 0) {
      await this.fetchNewChartData();
    }
    this.updateChart();
    this.$emit("chartLoaded");
  }

  // methods
  @Catch((error: any, context: any) => {
    context.$emit("isLoadingRecognitionSupplier", false);
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async fetchNewChartData() {
    // hide drill-down chart
    this.$emit("isLoadingRecognitionSupplier", true);
    const headerRecogChartForSupplier = document.querySelector(
      "#header-recog-for-supplier"
    );
    if (headerRecogChartForSupplier != null) {
      headerRecogChartForSupplier.classList.remove("active");
    }

    // refresh the chart data
    const chartData: {
      [key: string]: CategoryChartData[];
    } = await reportingAnalyticsService.loadRecogBySupplierAsync(
      this.dateRange.startDate,
      this.dateRange.endDate,
      true // include VS
    );
    this.recognitionStore.updateRecogBySupplierData(chartData.recognition);
    this.recognitionStore.updateSupplierInvoiceVolumeData(chartData.volume);
    this.$emit("isLoadingRecognitionSupplier", false);
  }

  updateChart() {
    this.series = [
      {
        name: "Recognition rate",
        data: this.recogBySupplierData.slice(0, 20),
        color: "#07445b"
      },
      {
        name: "Percent of total documents submitted",
        data: this.supplierInvoiceVolumeData.slice(0, 20),
        color: "#D06C49"
      }
    ];
  }

  // eslint-disable @typescript-eslint/no-unused-vars
  @Catch((error: any, context: any) => {
    context.$emit("errorCaptured", error);
    context.loadingHeaderFieldRecogData = false;
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async updateHeaderRecogChart(sourceChart: any, destChartIDToUpdate: any) {
    if (sourceChart.w.globals.selectedDataPoints[0]) {
      this.loadingHeaderFieldRecogData = true;

      const substringStartIndex = sourceChart.w.globals.labels[
        sourceChart.w.globals.selectedDataPoints[0][0]
      ].lastIndexOf("/");

      const selectedSupplierId = sourceChart.w.globals.labels[
        sourceChart.w.globals.selectedDataPoints[0][0]
      ].substring(substringStartIndex + 1);

      const headerRecogData = await reportingAnalyticsService.loadRecogByFieldForSupplierAsync(
        this.dateRange.startDate,
        this.dateRange.endDate,
        true,
        selectedSupplierId
      );

      this.headerRecogSeries = [
        {
          name: "Recognition rate",
          data: await headerRecogData.recogRate,
          color: "#D06C49"
        },
        {
          name: "Fields used to calculate rate",
          data: await headerRecogData.sampleSize,
          color: "#d3d3d3"
        }
      ];

      this.headerRecogChartOptions = {
        ...this.headerRecogChartOptions,
        subtitle: {
          text: `${sourceChart.w.globals.labels[
            sourceChart.w.globals.selectedDataPoints[0][0]
          ].substring(0, substringStartIndex)} (${sourceChart.w.globals.labels[
            sourceChart.w.globals.selectedDataPoints[0][0]
          ].substring(substringStartIndex + 1)}) for selected date range`,
          align: "left"
        }
      };

      this.loadingHeaderFieldRecogData = false;
    }
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewChartData();
    this.updateChart();
    this.$emit("chartLoaded");
  }
}
