






// Models
import {
  CategoryChartData,
  CategoryChartSeries
} from "@/models/reporting-analytics/category-chart-data";

// Services
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";

// Shared
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";

// Store
import RecognitionRateAnalyticsStore from "@/store/reporting-analytics/recognition-rate.store";

// Third Party Libs
import { getModule } from "vuex-module-decorators";
import { Vue, Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";

@Component({
  components: { apexchart: VueApexCharts }
})
export default class RecogByHeaderFieldChart extends Vue {
  // store module
  private readonly recognitionStore: RecognitionRateAnalyticsStore = getModule(
    RecognitionRateAnalyticsStore,
    this.$store
  );

  // computed properties
  get recogByHeaderFieldData() {
    return this.recognitionStore.getRecogByHeaderFieldData;
  }
  get dateRange() {
    return this.recognitionStore.getDateRange;
  }
  get searchButtonClicked() {
    return this.recognitionStore.getSearchButtonClicked;
  }

  // reactive class properties
  private series: CategoryChartSeries[] = [
    {
      name: "Recognition rate",
      data: []
    },
    {
      name: "Fields used to calculate rate",
      data: []
    }
  ];
  private chartOptions = {
    chart: {
      type: "bar",
      fontFamily: "'Lato', sans-serif",
      toolbar: {
        export: {
          csv: {
            filename: `header-field-recognition-${this.dateRange.startDate}-to-${this.dateRange.endDate}`,
            columnDelimiter: ",",
            headerCategory: "Field"
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    yaxis: [
      {
        title: {
          text: "Recognition rate (%)"
        },
        min: 0,
        max: 100
      },
      {
        opposite: true,
        title: {
          text: "Fields used to calculate rate"
        }
      }
    ],
    xaxis: {
      type: "category",
      title: {
        text: "Header field"
      }
    },
    title: {
      text: "Recognition Rate by Field",
      align: "left"
    },
    subtitle: {
      text: "Average across all suppliers for selected date range",
      align: "left"
    },
    legend: {
      show: false
    },
    tooltip: {
      shared: true,
      intersect: false,
      marker: {
        show: true
      },
      y: [
        {
          formatter: function(value: any) {
            if (value) {
              return value.toString().concat("%");
            }
          }
        },
        {
          formatter: function(value: any) {
            if (value) {
              return value;
            }
          }
        }
      ]
    },
    noData: {
      text: "No data for selected options",
      align: "center"
    }
  };

  // lifecycle methods
  async created() {
    if (Object.keys(this.recogByHeaderFieldData).length === 0) {
      await this.fetchNewChartData();
    }
    this.updateChart();
    this.$emit("chartLoaded");
  }

  // methods
  @Catch((error: any, context: any) => {
    context.$emit("isLoadingRecognitionHeader", false);
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async fetchNewChartData() {
    this.$emit("isLoadingRecognitionHeader", true);
    const chartData: {
      [key: string]: CategoryChartData[];
    } = await reportingAnalyticsService.loadRecogbyFieldAsync(
      this.dateRange.startDate,
      this.dateRange.endDate,
      true // include VS
    );
    this.recognitionStore.updateRecogByHeaderFieldData(chartData);
    this.$emit("isLoadingRecognitionHeader", false);
  }

  updateChart() {
    this.series = [
      {
        name: "Recognition rate",
        data: this.recogByHeaderFieldData.recogRate,
        color: "#D06C49"
      },
      {
        name: "Fields used to calculate rate",
        data: this.recogByHeaderFieldData.sampleSize,
        color: "#D3D3D3"
      }
    ];
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewChartData();
    this.updateChart();
    this.$emit("chartLoaded");
  }
}
